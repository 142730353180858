/* eslint-disable */
import React, { useState, useEffect } from "react";
import mobileImage from "../../assets/img/background_hero.png";
import heroImage from "../../assets/img/hero_image.png";
import getStarted from "../../assets/img/get_started_icon.svg"
import { Typography } from "@material-tailwind/react";
import { useTranslation } from 'react-i18next';

const HeroSection = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const mobileStyle = {
    backgroundImage: `url(${mobileImage})`, // set heroImage as background for mobile
    backgroundSize: "cover", // ensure it covers the whole element
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: "0",
    zIndex: 0

  };

  const desktopStyle = {
    backgroundImage: `url(${heroImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    borderRadius: " 0rem 0rem 23.875rem 0rem"
  };

  const isMobile = windowWidth <= 768;

  return (
    <div
      className={`relative h-screen flex flex-col justify-center items-start text-left bg-primary-800 ${isMobile ? "justify-center items-center bg-primary-800" : ""}`} // center items and text for mobile
      id="home"
      style={isMobile ? mobileStyle : desktopStyle}
    >
      <div
        className={`absolute flex flex-col justify-between items-start text-left md:w-1/3 h-full mt-60 md:ml-40 ${isMobile ? "flex-col justify-center items-center w-full mt-0 p-4 ml-0 text-center" : ""}`} // center content for mobile
      >
        <div className="md:mt-[15.5rem] md:mb-[1.25rem]">
          <Typography
            variant="h1"
            className={`text-5xl font-bold tracking-tight text-[#ffffff] font-nevan ${isMobile ? "text-3xl mb-4" : "text-7xl"
              }`}
          >
            Revanguard
          </Typography>
          <Typography
            className={`text-white w-9/12 mb-12 mt-[1.5rem] font-roboto ${isMobile ? "text-sm w-full px-4 text-center" : ""
              }`}
          >
            {t("hero_section")}
          </Typography>
          <a href="#contact" className="flex md:justify-start justify-center">
            <div className="relative flex items-center justify-center max-w-xs h-[3.625rem] w-[12.8125rem]">
              <img
                src={getStarted}
                alt="Footer"
                title="Footer"
                className="absolute inset-0 object-cover w-full h-full"
              />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
