/* eslint-disable */
import React, { useState } from "react";
import contactInfo from "../../assets/img/contact_icon.svg";
import toast from 'react-hot-toast';
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Grid,
  Container,
  CardContent,
  Typography,
  duration,
} from "@mui/material";
import emailjs from "emailjs-com";
import { useTranslation } from 'react-i18next';

const ContactForm = () => {
  const { t } = useTranslation();
  const [agree, setAgree] = useState(false);
  const [agreeNews, setAgreeNews] = useState(false);
  const [state, setState] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const isFormValid = () => {
    return (
      agree &&
      state.name.trim() !== "" &&
      state.phone.trim() !== "" &&
      state.email.trim() !== "" &&
      state.message.trim() !== ""
    );
  };

  const checkboxHandler = () => {
    setAgree(!agree);
  };

  const checkboxHandlerNewsletter = () => {
    setAgreeNews(!agreeNews);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const handleSubmit = (e) => {
    console.log("send: ", state);
    e.preventDefault();
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE,
        process.env.REACT_APP_EMAILJS_TEMPLACE,
        state,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (response) => {
          toast.success(t("contact_sent"))
          console.log("SUCCESS!", response.status, response.text);
          setState({
            name: "",
            phone: "",
            email: "",
            message: "",
          })
        },
        (error) => {
          toast.error(t("contact_error"))
          console.log("FAILED...", error);
        }
      );
  };

  return (
    <Container className="!m-0">
      <Grid
        container

        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "space-between",
          justifyContent: "center",
          marginBottom: "2rem",
          marginLeft: "0rem",
          marginRight: "0rem"
        }}
      >
        <Grid item>
          <CardContent
            style={{
              maxWidth: "38.875rem",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
              backgroundColor: "#04021E",
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              justifyContent: "center",
              borderRadius: "0.5rem",
              marginTop: "2rem",
              marginBottom: "2rem",
            }}
          >
            <form className="form" onSubmit={handleSubmit}>
              <TextField
                className="text-field"
                name="name"
                variant="outlined"
                placeholder={t("name")}
                required
                fullWidth
                value={state.name}
                onChange={handleChange}
                InputProps={{
                  style: { color: "#2b2728", borderRadius: "0.5rem", backgroundColor: "white" },
                }}
                InputLabelProps={{ style: { color: "#2b2728" } }}
                style={{
                  marginBottom: "1rem",
                }}
              />
              <div className="flex md:flex-row flex-col w-full md:space-x-4">
                <TextField
                  className="text-field"
                  name="phone"
                  variant="outlined"
                  required
                  fullWidth
                  placeholder={t("phone")}
                  value={state.phone}
                  onChange={handleChange}
                  InputProps={{
                    style: { color: "#2b2728", borderRadius: "0.5rem", backgroundColor: "white" },
                  }}
                  InputLabelProps={{ style: { color: "#2b2728" } }}
                  style={{ marginBottom: "1rem" }}
                />
                <TextField
                  className="text-field"
                  name="email"
                  variant="outlined"
                  required
                  fullWidth
                  placeholder={t("email")}
                  value={state.email}
                  onChange={handleChange}
                  InputProps={{
                    style: { color: "#2b2728", borderRadius: "0.5rem", backgroundColor: "white" },
                  }}
                  InputLabelProps={{ style: { color: "#2b2728" } }}
                  style={{ marginBottom: "1rem", borderRadius: "0.5rem" }}
                />
              </div>
              <TextField
                className="text-field"
                name="message"
                variant="outlined"
                required
                fullWidth
                multiline
                rows={4}
                placeholder={t("message")}
                value={state.message}
                onChange={handleChange}
                InputProps={{
                  style: { color: "#2b2728", borderRadius: "0.5rem", backgroundColor: "white" },
                }}
                InputLabelProps={{ style: { color: "#2b2728" } }}
                style={{ marginBottom: "1rem" }}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginBottom: "1rem" }}>
                  <FormControlLabel
                    label={
                      <Typography
                        variant="subtitle2"
                        style={{ color: "white", fontWeight: "bold" }}
                      >
                        {t("contact_terms")}
                      </Typography>
                    }
                    control={
                      <Checkbox
                        checked={agree}
                        onChange={checkboxHandler}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{
                          color: "#11c2d8",
                          "&.Mui-checked": {
                            color: "#11c2d8",
                          },
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label={
                      <Typography
                        variant="subtitle2"
                        style={{ color: "white", fontWeight: "bold" }}
                      >
                        {t("contact_info")}
                      </Typography>
                    }
                    control={
                      <Checkbox
                        checked={agreeNews}
                        onChange={checkboxHandlerNewsletter}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{
                          color: "#11c2d8",
                          "&.Mui-checked": {
                            color: "#11c2d8",
                          },
                        }}
                      />
                    }
                  />
                </div>
                <Button disabled={!isFormValid()} type="submit" className="relative flex items-center justify-center max-w-xs h-[3.5rem] w-[11.125rem] mr-[11rem] cursor-pointer">
                  <img
                    src={contactInfo}
                    alt="Footer"
                    title="Footer"
                    className="absolute inset-0 object-cover w-full h-full"
                  />
                  <Typography className="z-10 text-base font-semibold font-roboto text-[#ffffff]">
                    {t("send")}
                  </Typography>
                </Button>
              </div>
            </form>
          </CardContent>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactForm;
