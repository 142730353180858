/** @format */

import {
  people01,
  people02,
  people03,
  facebook,
  instagram,
  linkedin,
  twitter,
  airbnb,
  binance,
  coinbase,
  dropbox,
  send,
  shield,
  star,
  about01,
  about02,
  about03,
  about04,
  experience,
  expertise,
  clientCentered,
  salesforce,
  certified,
  crossmintLogo,
  beasyLogo,
} from "./../assets";

export const NAVBAR_ITEMS = ["About Us", "Work", "Team", "Contact"];
export const TECHNOLOGIES = [
  {
    title: "Client Outreach",
    description:
      "Initial contact and understanding of the project requirements.",
    direction: "left",
  },
  {
    title: "Proposal",
    description:
      "Preparation and submission of a project proposal to the client.",
    direction: "right",
  },
  {
    title: "Agreement",
    description: "Client approves the proposal and signs the contract.",
    direction: "left",
  },
  {
    title: "Planning",
    description: "In-depth project planning based on client's needs.",
    direction: "right",
  },
  {
    title: "Execution",
    description: "Design and development phase, including testing.",
    direction: "left",
  },
  {
    title: "Feedback & Revisions",
    description: "Regular updates to the client, incorporating their feedback.",
    direction: "right",
  },
  {
    title: "Finalization",
    description:
      "Quality assurance, final client approval, and any necessary revisions.",
    direction: "left",
  },
  {
    title: "Delivery",
    description: "Handing over the final product to the client.",
    direction: "right",
  },
  {
    title: "Support",
    description: "Post-delivery support and maintenance as per the agreement.",
    direction: "left",
  },
];
export const SECTIONS = [
  {
    title: "Digital Transformation Consulting",
    content:
      "Revanguard offer expert digital transformation consulting services to help businesses thrive in the digital era. We develop tailored strategies for innovation, process optimization, and efficiency improvement.",
    image: about01,
    reverse: false,
  },
  {
    title: "Custom Software Development",
    content:
      "Revanguard crafts tailor-made software solutions, empowering businesses with scalable and secure applications that meet their unique needs.",
    image: about02,
    reverse: true,
  },
  {
    title: "Cloud Solutions and Infrastructure",
    content:
      "Unlock the power of the cloud. Our cloud solutions and infrastructure services enable seamless migration, scalability, and cost-efficiency. Leverage cloud computing for flexible operations, collaboration, and accelerated innovation.",
    image: about03,
    reverse: false,
  },
  {
    title: "CRM Solutions",
    content:
      "Elevate customer relationships. Our CRM solutions optimize interactions, streamline sales processes, and boost customer satisfaction. From system implementation to tailored workflows and analytics, we empower businesses to cultivate personalized experiences and drive growth.",
    image: about04,
    reverse: true,
  },
  // Add more sections as needed...
];

export const EXPERIENCE = [
  {
    title: "Experience",
    content:
      "Years of industry expertise drive our success. From startups to enterprises, our team delivers exceptional technology solutions that fuel growth and success.",
    image: experience,
    reverse: false,
  },
  {
    title: "Expertise",
    content:
      "Stay ahead of the curve with our cutting-edge knowledge. We harness the latest technologies, from AI to data analytics, to tailor solutions that meet your unique business needs.",
    image: expertise,
    reverse: true,
  },
  {
    title: "Client-Centric Approach",
    content:
      "We immerse ourselves in your business, collaborating closely to develop tailored technology solutions. With open communication, we exceed expectations and deliver tangible results for your success.",
    image: clientCentered,
    reverse: false,
  },
  // Add more sections as needed...
];

export const FOOTER_COLUMNS = [
  {
    title: "Company",
    links: [
      { label: "Home", url: "/home", id: "home" },
      { label: "About Us", url: "/#aboutUs", id: "aboutUs" },
      { label: "Skills", url: "/#skills", id: "skills" },
      { label: "Contact", url: "/#contact", id: "contact" },
    ],
  },
  {
    title: "Location",
    links: [
      { label: "Barcelona" },
      { label: "Madrid" },
      { label: "Berlin" },
      { label: "Baia Mare" },
    ],
  },
  {
    title: "We Are Registered",
    links: [
      { label: "SalesForce", icon: salesforce },
      { label: "Certified", icon: certified },
    ],
  },
];

export const SOCIAL_MEDIA = [
  {
    title: "Be Social",
    links: [
      {
        id: "social-media-1",
        icon: instagram,
        link: "https://www.instagram.com/",
      },
      {
        id: "social-media-2",
        icon: facebook,
        link: "https://www.facebook.com/",
      },
      {
        id: "social-media-3",
        icon: twitter,
        link: "https://www.twitter.com/",
      },
      {
        id: "social-media-4",
        icon: linkedin,
        link: "https://www.linkedin.com/",
      },
    ],
  },
];

export const navLinks = [
  {
    id: "home",
    title: "HOME",
  },
  {
    id: "aboutUs",
    title: "ABOUT US",
  },
  {
    id: "skills",
    title: "SKILLS",
  },
];

export const feedback = [
  {
    id: "1",
    content:
      "Their expertise has been invaluable for us at Crossmint: in just a few weeks, they've made core contributions to our smart contracts design and implementation, that has reduced our costs, increased reliability and security, in ways that had never occurred to our team.",
    name: "Alfonso Gomez",
    title: "CEO Crossmint",
    stars: 5,
    img: crossmintLogo,
    url: "https://www.crossmint.com",
  },
  {
    id: "2",
    content:
      "Revanguard took our project from a mere idea to a solid, scalable product, attracting investors and customers along the way. Their expertise in emerging tech has been invaluable for scaling. They're not just developers; they're trusted partners.",
    name: "Bob Kramich",
    title: "CEO Beasy",
    stars: 5,
    img: beasyLogo,
    url: "https://www.easybeasy.com/",
  },
];
