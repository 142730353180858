/* eslint-disable */
import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import "./Skills.css";
import skill_1 from "../../assets/img/skill_1.svg";
import skill_2 from "../../assets/img/skill_2.svg";
import skill_3 from "../../assets/img/skill_3.svg";
import skill_4 from "../../assets/img/skill_4.svg";
import skill_5 from "../../assets/img/skill_5.svg";
import customer from "../../assets/img/customer-satisfaction.svg";
import customer_3 from "../../assets/img/customer_3.svg";
import { useTranslation } from 'react-i18next';

const Skills = () => {
  const { t, i18n } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [activeCard, setActiveCard] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!e.target.closest(".card")) {
        setActiveCard(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCardClick = (cardIndex, item) => {
    setSelectedCard(item)
    if (activeCard === cardIndex) {
      setActiveCard(null);
    } else {
      setActiveCard(cardIndex);
    }
  };


  const isMobile = windowWidth <= 768;
  const whyUs = [
    {
      id: 1,
      icon: skill_1,
      title: t("skills_1_title"),
      text: t("skills_1_text"),
    },
    {
      id: 2,
      icon: skill_2,
      title: t("skills_2_title"),
      text: t("skills_2_text"),
    },
    {
      id: 3,
      icon: skill_3,
      title: t("skills_3_title"),
      text: t("skills_3_text"),
    },
    {
      id: 4,
      icon: skill_4,
      title: t("skills_4_title"),
      text: t("skills_4_text"),
    },
  ];
  return (
    <div id="skills">
      <div className="about-us-container">
        <div className="about-title-wrapper">
          <Typography
            variant="h7"
            component="div"
            align="center"
            className="md:!text-3xl text-black font-nevan !mt-[2rem] md:w-[51rem] !leading-10 md:!mb-0 !mb-[-5rem]"
          >
            {t("why_us")}
          </Typography>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center">
        <div className="flex md:flex-row flex-col md:space-x-6 md:space-y-0 space-y-6 md:w-[67.5rem] md:mb-0 mb-12">
          {isMobile ? (
            <>
              <div class="group h-full w-auto [perspective:1000px]">
                <div class="relative h-full w-full rounded-md transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                  <div className="flex flex-col w-[15.75rem] md:h-[15.313rem] h-[20.313rem] bg-white rounded-[5px] border border-[#00c2e0] items-center ">
                    <img
                      src={skill_1}
                      alt="Icon 4"
                      className="w-auto h-[5.3rem] mb-[1.75rem] mt-[3rem]"
                    />
                    <Typography className="font-roboto text-xl text-black !font-semibold w-[200px] text-center">{t("skills_1_title")}</Typography>
                  </div>
                  <div class="absolute inset-0 h-full w-full rounded-md bg-gradient-to-b from-[#05a9f6] to-[#23eca4] px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                    <div class="flex min-h-full flex-col items-center justify-center space-y-4">
                      <Typography className="font-roboto text-xl text-white !font-semibold w-[200px] text-center">{t("skills_1_title")}</Typography>
                      <Typography className="!text-sm  text-white w-[250px]">{t("skills_1_text")}</Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div class="group h-full w-auto [perspective:1000px]">
                <div class="relative h-full w-full rounded-md transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                  <div className="flex flex-col w-[15.75rem] md:h-[15.313rem] h-[20.313rem] bg-white rounded-[5px] border border-[#00c2e0] items-center ">
                    <img
                      src={skill_2}
                      alt="Icon 4"
                      className="w-auto h-[5.3rem] mb-[1.75rem] mt-[3rem]"
                    />
                    <Typography className="font-roboto text-xl text-black !font-semibold w-[200px] text-center">{t("skills_2_title")}</Typography>
                  </div>
                  <div class="absolute inset-0 h-full w-full rounded-md bg-gradient-to-b from-[#05a9f6] to-[#23eca4] px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                    <div class="flex min-h-full flex-col items-center justify-center space-y-4">
                      <Typography className="font-roboto text-xl text-white !font-semibold w-[200px] text-center">{t("skills_2_title")}</Typography>
                      <Typography className="!text-sm  text-white w-[250px]">{t("skills_2_text")}</Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div class="group h-full w-auto [perspective:1000px]">
                <div class="relative h-full w-full rounded-md transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                  <div className="flex flex-col w-[15.75rem] md:h-[15.313rem] h-[20.313rem] bg-white rounded-[5px] border border-[#00c2e0] items-center ">
                    <img
                      src={skill_3}
                      alt="Icon 4"
                      className="w-auto h-[5.3rem] mb-[1.75rem] mt-[3rem]"
                    />
                    <Typography className="font-roboto text-xl text-black !font-semibold w-[200px] text-center">{t("skills_3_title")}</Typography>
                  </div>
                  <div class="absolute inset-0 h-full w-full rounded-md bg-gradient-to-b from-[#05a9f6] to-[#23eca4] px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                    <div class="flex min-h-full flex-col items-center justify-center space-y-4">
                      <Typography className="font-roboto text-xl text-white !font-semibold w-[200px] text-center">{t("skills_3_title")}</Typography>
                      <Typography className="!text-sm  text-white w-[250px]">{t("skills_3_text")}</Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div class="group h-full w-auto [perspective:1000px]">
                <div class="relative h-full w-full rounded-md transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                  <div className="flex flex-col w-[15.75rem] md:h-[15.313rem] h-[20.313rem] bg-white rounded-[5px] border border-[#00c2e0] items-center ">
                    <img
                      src={skill_4}
                      alt="Icon 4"
                      className="w-auto h-[5.3rem] mb-[1.75rem] mt-[3rem]"
                    />
                    <Typography className="font-roboto text-xl text-black !font-semibold w-[200px] text-center">{t("skills_4_title")}</Typography>
                  </div>
                  <div class="absolute inset-0 h-full w-full rounded-md bg-gradient-to-b from-[#05a9f6] to-[#23eca4] px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                    <div class="flex min-h-full flex-col items-center justify-center space-y-4">
                      <Typography className="font-roboto text-xl text-white !font-semibold w-[200px] text-center">{t("skills_4_title")}</Typography>
                      <Typography className="!text-sm  text-white w-[250px]">{t("skills_4_text")}</Typography>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col justify-center items-center mb-16">
                <div className="flex flex-row space-x-4">
                  {whyUs.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => handleCardClick(index, item)}
                      className={`card flex flex-col w-[15.75rem] h-[15.313rem] ${activeCard === index ? "bg-gradient-to-b from-[#05a9f6] to-[#23eca4]" : "bg-white"} rounded-[5px] border border-[#00c2e0] items-center cursor-pointer`}
                    >
                      <img
                        src={item.icon}
                        alt="Icon 4"
                        className="w-auto h-[5.3rem] mb-[1.75rem] mt-[3rem]"
                      />
                      <Typography className="font-roboto text-xl text-black !font-semibold w-[220px] text-center">{item.title}</Typography>
                    </div>
                  ))}
                </div>

                {activeCard !== null && (
                  <div className="flex flex-row my-20 w-[67.5rem] h-[20rem] border border-[#00c2e0] rounded-[5px] justify-between items-center">
                    <img
                      src={selectedCard.icon}
                      alt="Icon 4"
                      className="w-auto h-[15rem] my-[2.5rem] ml-[6rem]"
                    />
                    <div className="flex flex-col">
                      <Typography
                        variant="h7"
                        component="div"
                        className="text-left !text-3xl text-black font-nevan w-[33rem] !leading-10 !mr-[3.5rem] !mb-[1.5rem]"
                      >
                        {selectedCard.title}
                      </Typography>
                      <Typography className="text-left w-[31.5rem] font-normal text-[#383838] font-roboto">
                        {selectedCard.text}
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

        </div>



      </div>

      <div className="bg-[#04021E] flex flex-col items-center">
        <Typography
          variant="h7"
          component="div"
          align="center"
          className="md:!text-3xl text-white font-nevan !mt-[5rem] !mb-[3rem] md:w-[43rem] !leading-10 md:px-0 px-4"
        >
          {t('skills_cards_title')}
        </Typography>

        <div class="flex md:flex-row flex-col items-center justify-center bg-slate-100 md:space-x-20">
          <div>
            <div class="group h-[19.5rem] w-[18.13rem] [perspective:1000px]">
              <div class="relative h-full w-full rounded-md shadow-xl ">
                <div className="absolute inset-0 md:p-0 p-6">
                  <div className="flex bg-gradient-to-b from-[#00c2e0] to-[#34d399] h-full w-full rounded-md justify-center items-center">
                    <img src={customer} alt="customer" className="w-auto h-[8rem] mx-[5.13rem] md:p-0 p-4" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-[16.875rem] text-center !mb-[7.5rem] mx-auto">
              <Typography className="!font-nevan font-bold md:!text-2xl !text-xl text-white !my-4">
                {t('skills_card_1_title')}
              </Typography>
              <Typography className="!font-roboto font-normal !text-base text-white">
                {t('skills_card_1_text')}
              </Typography>
            </div>
          </div>
          <div>
            <div class="group h-[19.5rem] w-[18.13rem] [perspective:1000px]">
              <div class="relative h-full w-full rounded-md shadow-xl">
                <div className="absolute inset-0 md:p-0 p-6">
                  <div className="flex bg-gradient-to-b from-[#00c2e0] to-[#34d399] h-full w-full rounded-md justify-center items-center">
                    <img src={customer} alt="customer" className="w-auto h-[8rem] mx-[5.13rem] md:p-0 p-4" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-[16.875rem] text-center !mb-[7.5rem] mx-auto">
              <Typography className="!font-nevan font-bold md:!text-2xl !text-xl text-white !my-4">
                {t('skills_card_2_title')}

              </Typography>
              <Typography className="!font-roboto font-normal !text-base text-white">
                {t('skills_card_2_text')}
              </Typography>
            </div>
          </div>
          <div>
            <div class="group h-[19.5rem] w-[18.13rem] [perspective:1000px]">
              <div class="relative h-full w-full rounded-md shadow-xl ">
                <div className="absolute inset-0 md:p-0 p-6">
                  <div className="flex bg-gradient-to-b from-[#00c2e0] to-[#34d399] h-full w-full rounded-md justify-center items-center ">
                    <img src={customer_3} alt="customer" className="w-auto h-[8rem] mx-[5.13rem] md:p-0 p-4" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-[16.875rem] text-center !mb-[7.5rem] mx-auto">
              <Typography className="!font-nevan font-bold md:!text-2xl !text-xl text-white !my-4">
                {t('skills_card_3_title')}
              </Typography>
              <Typography className="!font-roboto font-normal !text-base text-white">
                {t('skills_card_3_text')}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Skills;
