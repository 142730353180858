/** @format */

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Box from "@mui/material/Box";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import ContactForm from "./components/ContactForm/ContactForm";
import HeroSection from "./components/HeroSection/HeroSection";
import TestimonialGrid from "./components/Testimonials/Testimonials";
import { navLinks, feedback } from "./constants";
import "./App.css";
import AboutUs from "./components/AboutUs/AboutUs";
import Skills from "./components/Skills/Skills";
import { Typography } from "@mui/material";
import instagram from "./assets/img/instagramIcon.svg";
import twitter from "./assets/img/twitterIcon.svg";
import linkedin from "./assets/img/linkedinIcon.svg";
import { useTranslation } from "react-i18next";

const App = () => {
  const { t } = useTranslation();
  const isMobile = window.innerWidth <= 768;
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Router>
        <div className="gradient__bg">
          <Navbar items={navLinks} />
          <HeroSection />
        </div>
        <AboutUs />
        <div style={{ backgroundColor: "white" }}>
          <Skills />
        </div>
        <TestimonialGrid />
        <div
          className="flex md:flex-row flex-col md:mx-[11.25rem] items-center"
          id="contact"
        >
          <div className="flex flex-col">
            <Typography
              variant="h4"
              className="!font-nevan !text-3xl !mb-4 md:text-left text-center md:!mt-0 !mt-8"
            >
              {t("contact_title")}
            </Typography>
            {!isMobile && (
              <div className="flex flex-col space-y-4">
                <div className="flex flex-row items-center">
                  <img
                    src={instagram}
                    alt="Icon 1"
                    className="w-[3.75rem] h-[3.625rem] z-0 mr-8"
                  />

                  <Typography className="!font-nevan !font-base ml-4">
                    INSTAGRAM
                  </Typography>
                </div>

                <div className="flex flex-row items-center">
                  <img
                    src={twitter}
                    alt="Icon 1"
                    className="w-[3.75rem] h-[3.625rem] z-0 mr-8"
                  />

                  <Typography className="!font-nevan !font-base ml-4">
                    TWITTER
                  </Typography>
                </div>
                <a
                  href="https://www.linkedin.com/company/revanguard/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="flex flex-row items-center">
                    <img
                      src={linkedin}
                      alt="Icon 1"
                      className="w-[3.75rem] h-[3.625rem] z-0 mr-8"
                    />

                    <Typography className="!font-nevan !font-base ml-4">
                      LINKEDIN
                    </Typography>
                  </div>
                </a>
              </div>
            )}
          </div>

          <ContactForm />
        </div>
        <Footer year={new Date().getFullYear()} company="Revanguard" />
        <Typography className="flex w-full bg-[#04021E] text-white justify-center py-4 !font-roboto">
          {" "}
          &copy; {new Date().getFullYear()} Revanguard. {t("copyright")}
        </Typography>
      </Router>
    </Box>
  );
};

export default App;
