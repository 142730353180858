/* eslint-disable */
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import aboutUs from "../../assets/img/about_us_desktop_title.svg";
import about_1 from "../../assets/img/about_1.svg";
import about_2 from "../../assets/img/about_2.svg";
import about_3 from "../../assets/img/about_3.svg";
import back_1 from "../../assets/img/rectangle_1.svg";
import back_2 from "../../assets/img/rectangle_2.svg";
import back_3 from "../../assets/img/rectangle_3.svg";
import about_11 from "../../assets/img/about_11.svg";
import about_22 from "../../assets/img/about_22.svg";
import about_33 from "../../assets/img/about_33.svg";
import about_44 from "../../assets/img/about_44.svg";
import about_mobile_1 from "../../assets/img/about_mobile_1.svg";
import about_mobile_2 from "../../assets/img/about_mobile_2.svg";
import { useTranslation } from 'react-i18next';

import "./AboutUs.css";
import {
  icono1about,
  icono2about,
  icono3about,
  icono4about,
} from "../../assets";

const AboutUs = () => {
  const { t, i18n } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const mobileStyle = {
    backgroundColor: "#0a0632",
  };
  const isMobile = window.innerWidth <= 768;

  return (
    <div id="aboutUs">
      <div className="about-us-container">
        <div className="flex ">
          <Typography
            variant="h7"
            component="div"
            align="center"
            className="md:!text-3xl md:px-0 px-8 text-black font-nevan w-auto !leading-10"
          >
            {t("about_us_who_we_are")}
          </Typography>
        </div>
      </div>

      <div className="flex md:flex-row flex-col justify-center items-center h-auto">
        <div className="relative h-[25.5rem] md:mx-0 mx-12">
          <img src={back_1} alt="Footer" title="Footer" className="inset-0 object-cover h-[21.5] w-[22.5rem] z-0" />
          <div className="absolute top-[-2.6rem] left-0 space-y-4 max-h-[24rem] max-w-[21.5rem] overflow-hidden z-10">
            <img src={about_1} alt="Footer" title="Footer" className="object-cover h-[21.5] w-[20.5rem] mx-auto md:px-0 px-4" />
            <Typography className="overflow-y-auto px-6 !font-roboto !text-xl !leading-8">
              {t("about_us_approach")}
            </Typography>
          </div>
        </div>


        <div className="relative h-[25.5rem] md:mx-0 mx-12">
          <img src={back_2} alt="Footer" title="Footer" className="inset-0 object-cover h-[21.5] w-[22.5rem] z-0" />
          <div className="absolute top-[-2.6rem] left-0 space-y-4 max-h-[24rem] max-w-[21.5rem] overflow-hidden z-10">
            <img src={about_2} alt="Footer" title="Footer" className="object-cover h-[21.5] w-[20.5rem] mx-auto md:px-0 px-4" />
            <Typography className="overflow-y-auto px-6 !font-roboto !text-xl !leading-8">
              {t("about_us_expertise")}
            </Typography>
          </div>
        </div>

        <div className="relative h-[25.5rem] md:mx-0 mx-12">
          <img src={back_3} alt="Footer" title="Footer" className="inset-0 object-cover h-[21.5] w-[22.5rem] z-0" />
          <div className="absolute top-[-2.6rem] left-0 space-y-4 max-h-[24rem] max-w-[21.5rem] overflow-hidden z-10">
            <img src={about_3} alt="Footer" title="Footer" className="object-cover h-[21.5] w-[20.5rem] mx-auto md:px-0 px-4" />
            <Typography className="overflow-y-auto px-6 !font-roboto !text-xl !leading-8">
              {t("about_us_success")}
            </Typography>
          </div>
        </div>
      </div>


      {!isMobile && (
        <div className="flex flex-col h-auto w-full bg-[#04021E]">
          <div className="lower-section-content">
            <Typography
              variant="h7"
              component="div"
              align="center"
              className="!text-3xl text-white font-nevan !mt-[5.5rem] w-[51rem] !leading-10"
            >
              {t("about_us_pioneer")}
            </Typography>
            {!isMobile && (
              <Typography
                variant="h7"
                component="div"
                align="center"
                className="!text-lg text-white font-roboto !my-[2rem]"
              >
                {t("about_us_growth")}
              </Typography>
            )}
            <div className="flex md:flex-row flex-col space-x-32 !mb-[5.5rem]">
              <img
                src={about_11}
                alt="Icon 1"
                className="w-[12rem] h-[12rem]"
              />
              <img
                src={about_22}
                alt="Icon 2"
                className="w-[12rem] h-[12rem]"
              />
              <img
                src={about_33}
                alt="Icon 3"
                className="w-[12rem] h-[12rem]"
              />
              <img
                src={about_44}
                alt="Icon 4"
                className="w-[12rem] h-[12rem]"
              />
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default AboutUs;
