/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Typography } from '@material-tailwind/react';
import testimonial_base from "../../assets/img/testimonials_base.svg";
import Carousel from 'react-multi-carousel';
import { useTranslation } from 'react-i18next';
import {
    crossmintLogo,
    beasyLogo,
    mrdsignLogo,
    wildfireLogo
} from "./../../assets";

function StarIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="h-4 w-4 text-yellow-700"
        >
            <path
                fillRule="evenodd"
                d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                clipRule="evenodd"
            />
        </svg>
    );
}

function StarIconDisabled() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="h-4 w-4 text-gray-700"
        >
            <path
                fillRule="evenodd"
                d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                clipRule="evenodd"
            />
        </svg>
    );
}

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};
const TestimonialGrid = () => {
    const { t } = useTranslation();
    const feedback = [
        {
            id: "1",
            content:
                t("testimonial_crossmint"),
            name: "Alfonso Gomez",
            title: "CEO Crossmint",
            stars: 5,
            img: crossmintLogo,
            url: "https://www.crossmint.com",
        },
        {
            id: "2",
            content:
                t("testimonial_beasy"),
            name: "Bob Kramich",
            title: "CEO Beasy",
            stars: 5,
            img: beasyLogo,
            url: "https://www.easybeasy.com/",
        },
        {
            id: "3",
            content:
                t("testimonial_mrdsign"),
            name: "Mireia Rojas",
            title: "CEO MRDsign",
            stars: 5,
            img: mrdsignLogo,
            url: "https://www.linkedin.com/company/mrdsign/",
        },
        {
            id: "4",
            content:
                t("testimonial_wildfire"),
            name: "Daniel Algattas",
            title: "CEO Wildfire",
            stars: 5,
            img: wildfireLogo,
            url: "",
        },
    ];
    return (
        <div className='flex md:flex-row flex-col bg-[#142A4F] md:py-24 pt-8'>
            <div className='flex flex-col md:items-start items-center !text-left my-auto mx-8 '>
                <Typography className='font-nevan text-base text-white my-8'>
                    {t("testimonials_title")}
                </Typography>
                <Typography className='font-nevan md:text-3xl text-2xl text-white md:w-[23rem] text-center md:text-left'>
                    {t("testimonials_about")}
                </Typography>
                <Typography className='font-roboto md:text-md text-md text-white md:w-[23rem] text-center md:text-left mt-8'>
                    {t("testimonials_text")}
                </Typography>
            </div>
            <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={2000}
                draggable={true}
                arrows={false}
                className='w-full'
            >
                {feedback.map((item, index) => (
                    <div className='relative flex flex-col mx-8 h-auto md:!my-0 !my-12' key={index}>
                        <img src={testimonial_base} alt="customer" className="w-auto h-auto" />
                        <div className="absolute left-[-5%]">
                            <a href={item.url} target="_blank" rel="noopener noreferrer">
                                <img src={item.img} alt="reviewer" className="md:w-[6.5rem] w-[5rem] h-auto rounded-full border-4 border-white" />
                            </a>
                        </div>
                        <div className="absolute top-1/4 flex flex-row items-center space-x-4 justify-between w-full">
                            <div className='flex flex-col ml-24'>
                                <Typography className="text-white font-bold font-nevan md:!text-lg !text-xs">{item.name}</Typography>
                                <Typography className="text-white font-bold font-nevan md:!text-lg !text-xs">{item.title}</Typography>
                            </div>
                            <div className="flex">
                                {Array.from({ length: 5 }).map((_, starIndex) =>
                                    starIndex < item.stars ? <StarIcon key={starIndex} /> : <StarIconDisabled key={starIndex} />
                                )}
                            </div>
                        </div>
                        <div className="absolute bottom-5 left-0 w-full h-[6rem] p-2">
                            <Typography className="text-white font-roboto leading-none md:!text-md !text-xs md:!mt-0 !mt-4">{item.content}</Typography>
                        </div>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default TestimonialGrid;