/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Grid, Typography, Link, Divider, CardMedia } from "@mui/material";
import { logoRevanguard } from "../../assets";
import instagram from "../../assets/img/instagramIcon.svg";
import twitter from "../../assets/img/twitterIcon.svg";
import linkedin from "../../assets/img/linkedinIcon.svg";
import { useTranslation } from 'react-i18next';
import {
  salesforce,
  certified,
} from "./../../assets";
import "./Footer.css";




const Footer = () => {
  const { t } = useTranslation();

  const FOOTER_COLUMNS = [
    {
      title: t("company"),
      links: [
        { label: t("home_footer"), url: "/home", id: "home" },
        { label: t("about_us_footer"), url: "/#aboutUs", id: "aboutUs" },
        { label: t("skills_footer"), url: "/#skills", id: "skills" },
        { label: t("contact_us"), url: "/#contact", id: "contact" },
      ],
    },
    {
      title: t("location"),
      links: [
        { label: "Barcelona" },
        { label: "Madrid" },
        { label: "Berlin" },
        { label: "Baia Mare" },
      ],
    },
    {
      title: t("registered"),
      links: [
        { label: "SalesForce", icon: salesforce },
        { label: "Certified", icon: certified },
      ],
    },
  ];

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowWidth <= 640;

  return (
    <footer
      className="original-footer"
      style={{ backgroundColor: isMobile ? "#04021E" : "#04021E" }}
    >
      {isMobile ? (
        <>
          <div className="mobile-footer">
            <div className="flex flex-col w-auto space-y-4">
              <CardMedia
                component="img"
                alt="Footer"
                image={logoRevanguard}
                title="Footer"
                className="!w-[5rem] h-auto"
              />
            </div>
            <div className="flex flex-col">
              <div className="flex flex-row space-x-4">
                {FOOTER_COLUMNS.map((column, index) => (
                  <div key={index} className="mobile-column">
                    <Typography
                      className="!font-nevan space-y-4"
                      variant="h6"
                      gutterBottom
                      style={{
                        fontSize: "0.8rem",
                        color: "white",
                        marginBottom: "0px",
                        fontWeight: "bold",
                      }}
                    >
                      {column.title}
                    </Typography>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {column.links.map((link, index) =>
                        link.icon ? (
                          <ul
                            key={index}
                            style={{
                              listStyle: "none",
                              justifyContent: "center",
                              justifyItems: "center",
                              margin: "0",
                            }}
                          >
                            <li key={index}>
                              <CardMedia
                                className="!font-roboto"
                                component="img"
                                image={link.icon}
                                alt={link.id}
                                style={{ width: "35px", height: "auto" }}
                                href={link.link}
                              />
                            </li>
                          </ul>
                        ) : null
                      )}
                    </div>

                    <ul style={{ listStyle: "none", paddingLeft: 0 }}>
                      {column.links.map((link, index) =>
                        !link.icon ? (
                          <li
                            key={index}
                            style={{
                              marginBottom: "0px",
                              height: "15px",
                            }}
                          >
                            <a
                              className="link"
                              href={link.url}
                              style={{
                                color: "white",
                                fontSize: "0.6rem",
                              }}
                            >
                              {link.label}
                            </a>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </div>
                ))}
              </div>
              <div className="flex flex-row space-x-2 justify-center">
                <div className="flex flex-row items-center w-[2rem] h-[2rem]">
                  <img
                    src={instagram}
                    alt="Icon 1"
                    className=" z-0"
                  />
                  <Typography className="!font-nevan !font-base">
                    INSTAGRAM
                  </Typography>
                </div>

                <div className="flex flex-row items-center  w-[2rem] h-[2rem]">
                  <img
                    src={twitter}
                    alt="Icon 1"
                    className="z-0"
                  />

                  <Typography className="!font-nevan !font-base">
                    TWITTER
                  </Typography>
                </div>
                <a href="https://www.linkedin.com/company/revanguard/" target="_blank" rel="noopener noreferrer">
                  <div className="flex flex-row items-center w-[2rem] h-[2rem] cursor-pointer hover:opacity-80">
                    <img src={linkedin} alt="Icon 1" className="z-0" />
                    <Typography className="!font-nevan !font-base">
                      LINKEDIN
                    </Typography>
                  </div>
                </a>

              </div>
            </div>

          </div>
          <Divider
            style={{
              backgroundColor: "#6c6a7d",
              width: "90%",
              margin: "auto",
            }}
          />
        </>
      ) : (
        <Grid
          container
          justifyContent="center"
          spacing={4}
          className="footer-main"
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            style={{ textAlign: "-webkit-center" }}
          >
            <div className="flex flex-col w-[40%] space-y-4">
              <CardMedia
                component="img"
                alt="Footer"
                image={logoRevanguard}
                title="Footer"
                className="!w-[6.5rem] h-auto"
              />
              <Typography className="flex !font-nevan !text-sm text-white">Revanguard</Typography>
              <Typography className="flex !font-roboto !text-sm text-white text-left w-[15rem]">
                {t("footer_text")}</Typography>
            </div>
          </Grid>
          {FOOTER_COLUMNS.map((column, index) => (
            <Grid key={index} item xs={3} sm={6} md={3}>
              <div className="columns-footer">
                <Typography
                  className="!font-nevan"
                  variant="h6"
                  gutterBottom
                  style={{ fontSize: "1.2rem", color: "white" }}
                >
                  {column.title}
                </Typography>
                <ul
                  style={{
                    listStyle: "none",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  {column.links.map((link, index) =>
                    link.icon ? (
                      <li key={index} style={{ marginRight: "20px" }}>
                        <CardMedia
                          className="!font-roboto"
                          component="img"
                          image={link.icon}
                          alt={link.id}
                          style={{ width: "70px", height: "auto" }}
                          href={link.link}
                        />
                      </li>
                    ) : null
                  )}
                </ul>

                <ul style={{ listStyle: "none", paddingLeft: 0 }}>
                  {column.links.map((link, index) =>
                    !link.icon ? (
                      <li key={index}>
                        <a

                          className="!font-roboto"
                          href={link.url}
                          style={{ color: "white", textDecoration: "none" }}
                        >
                          {link.label}
                        </a>
                      </li>
                    ) : null
                  )}
                </ul>
              </div>
            </Grid>
          ))}
        </Grid>
      )}
    </footer>
  );
};

export default Footer;
