/** @format */

import React, { useState, useEffect } from "react";
import { Link as ScrollLink } from "react-scroll";
import logoRevanguard from "../../assets/img/rev_logo.svg";
import contactInfo from "../../assets/img/contact_icon.svg";
import { Typography } from "@material-tailwind/react";
import { useTranslation } from 'react-i18next';
import { US, ES } from 'country-flag-icons/react/3x2'

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const navLinks = [
    {
      id: "home",
      title: t("home"),
    },
    {
      id: "aboutUs",
      title: t("about_us"),
    },
    {
      id: "skills",
      title: t("skills"),
    },
  ];

  const [active, setActive] = useState("Home");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);

  const isMobile = window.innerWidth <= 768;

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const maxScroll =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    setShowScrollTop(currentScrollPos > 300);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const handleLanguageChange = (lang) => {
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang);
    setIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const renderMenu = () => {
    if (isMobile) {
      return (
        <div>
          <button onClick={() => setDrawerOpen(true)} className="text-black font-bold text-xl">
            ☰
          </button>
          {drawerOpen && (
            <div className="fixed top-0 left-0 h-full w-full bg-gray-700 flex justify-center items-center z-50 ">
              <div className="absolute top-5 right-5">
                <button
                  onClick={() => setDrawerOpen(false)}
                  className="text-white"
                >
                  ✖
                </button>
              </div>
              <ul className="space-y-5">
                {navLinks.map((nav) => (
                  <li key={nav.id} onClick={() => setActive(nav.title)}>
                    <ScrollLink
                      to={nav.id}
                      spy={true}
                      smooth={true}
                      duration={500}
                      className={
                        active === nav.title
                          ? "text-black font-roboto"
                          : "text-white font-roboto"
                      }
                      onClick={() => setDrawerOpen(false)}
                    >
                      {nav.title}
                    </ScrollLink>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <ul className="flex flex-row space-x-4 list-none items-center">
          {navLinks.map((nav) => (
            <li
              key={nav.id}
              onClick={() => setActive(nav.title)}
              className="m-0"
            >
              <a
                href={`#${nav.id}`}
                className={
                  active === nav.title
                    ? "text-[#6D6D6D] font-roboto font-bold"
                    : "text-[#6D6D6D] font-roboto font-medium"
                }
              >
                {nav.title}
              </a>
            </li>
          ))}
        </ul>
      );
    }
  };

  return (
    <div
      className={`fixed w-full md:p-5 p-8 flex justify-between items-center bg-[#ffffff] z-50 md:h-[4.75rem] h-[3rem]`}
    >
      <div className="flex flex-row items-center md:ml-[11rem]">
        <img
          src={logoRevanguard}
          alt="Footer"
          title="Footer"
          className="object-cover md:h-[3.8125rem] h-[3rem] w-auto"
        />
        <Typography className="flex flex-col items-center justify-center w-31 h-9 text-[#035988] text-center !font-nevan md:!text-lg !text-md font-semibold ml-2">
          REVANGUARD
        </Typography>

      </div>
      <div className="relative">
        <button onClick={() => setIsOpen(!isOpen)} className="flex items-center">
          {isMobile ? null :
            <span className="mr-2 !text-[#6D6D6D] font-roboto font-medium">
              {selectedLanguage === 'en' ? 'English' : 'Español'}
            </span>}
          <span className="mr-2">{isOpen ? '▲' : '▼'}</span>
          {selectedLanguage === 'en' ? <US title="United States" className="h-8 w-8" /> : <ES title="Spain" className="h-8 w-8" />}
        </button>

        {isOpen && (
          <div className="absolute mt-2 w-auto rounded-md shadow-lg bg-white z-50">
            <div onClick={() => handleLanguageChange('en')} className="cursor-pointer hover:bg-gray-200 p-2 flex items-center">
              <Typography className="mr-2 !text-[#6D6D6D] font-roboto font-medium">English</Typography>
              <US title="United States" className="h-8 w-8" />
            </div>
            <div onClick={() => handleLanguageChange('es')} className="cursor-pointer hover:bg-gray-200 p-2 flex items-center">
              <Typography className="mr-2 !text-[#6D6D6D] font-roboto font-medium">Español</Typography>
              <ES title="Spain" className="h-8 w-8" />
            </div>
          </div>
        )}
      </div>
      {renderMenu()}
      {showScrollTop && (
        <button
          onClick={handleScrollToTop}
          className="fixed bottom-5 left-5 z-50"
        >
          <div className="bg-[#11c2d8] py-1 h-8 w-8 rounded-full">↑</div>
        </button>
      )}
      {!isMobile && (
        <a href="#contact">
          <div className="relative flex items-center justify-center max-w-xs h-[3.5rem] w-[11.125rem]  mr-[11rem]">
            <img
              src={contactInfo}
              alt="Footer"
              title="Footer"
              className="absolute inset-0 object-cover w-full h-full"
            />
            <Typography className="z-10 text-base font-semibold font-roboto text-[#ffffff]">
              {t('contact_us')}
            </Typography>
          </div>
        </a>
      )}

    </div>
  );
};

export default Navbar;
